.App {
}

.App__toast {
  background: #00b5ad;
}
.App__toast--success {
  /* background: #00b5ad; */
}

.App__toast--ok {
  background: #fcfff5;
  color: #000000;
}

.App__toast--failure {
  background: #fc5a50; /*coral*/
  color: #000000;
}

.App__main {
  font-size: 100%;
  text-align: center;
}

